import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import {iconsSet as icons} from './assets/icons/icons.js'
import store from './store'
import {register} from 'register-service-worker'
import firebase from 'firebase/app'
import 'firebase/firebase-messaging'
import http from './http-common'

register('firebase-messaging-sw.js')

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

var firebaseConfig = {
    apiKey: "AIzaSyBnrdjBTjW8GqxtYHxWcYXKW2SCivhb7mE",
    authDomain: "predict-cf1b9.firebaseapp.com",
    projectId: "predict-cf1b9",
    storageBucket: "predict-cf1b9.appspot.com",
    messagingSenderId: "583780790128",
    appId: "1:583780790128:web:9e63fb6ab69bcf2a57a9b5"
};

try {
    firebase.initializeApp(firebaseConfig)

        firebase.messaging().getToken({vapidKey: 'BJ34jFqwrn5ttxlBOrVb8_Ew90GP7DxBh6Bne_YEi-14UGeRUYJ2vi24iE2yNrYxzfes8ogfiZMlajXgy17C5vQ'})
            .then((currentToken) => {
                if (currentToken) {
                    console.log('client token', currentToken)
                    http.get('/nouveau/' + currentToken)
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        })

} catch (err) {
    console.log(err);
}

new Vue({
    el: '#app',
    router,
    store,
    icons,
    template: '<App/>',
    components: {
        App
    }
})
